<script setup lang="ts">
import { AuthenticationError, AuthorizationError } from "@gasparigit/omnia-sdk";

import "@fontsource/lato";
import { ChevronRight, ChevronsUpDown } from "lucide-vue-next";
import "~/assets/css/admin.css";
import { userHasRole } from "~/lib/utils/contents";

const omniaSdk = useOmniaSdk();

const preview = usePreviewMode();
const colorMode = useColorMode();

const { data: loggedUser, status } = await useLoggedUser(
  (error: any) => {
    if (error instanceof AuthenticationError || error instanceof AuthorizationError) navigateTo("/");
  },
  true,
  (user) => {
    if (!user || !userHasRole(user, ["SuperAdmin", "Amministratore", "Editore", "Ufficio clienti"])) navigateTo("/");
  }
);

if (!loggedUser.value || !userHasRole(loggedUser.value, ["SuperAdmin", "Amministratore", "Editore", "Ufficio clienti"])) navigateTo("/");

const route = useRoute();

async function logout() {
  await omniaSdk.auth.user.logout();
  navigateTo("/");
}

type MenuItem = {
  title: string;
  href?: string;
  alternativeHref?: string;
  icon: string;
  roles?: string[];
  isActive?: boolean;
};

const menuTree = [
  {
    title: "Contenuti",
    items: [
      {
        title: "Indice",
        icon: "carbon:network-1",
        roles: ["Editore"],
        items: [
          {
            title: "Aree",
            href: "/admin/aree",
            icon: "carbon:data-categorical",
          },
          {
            title: "Menù e Hub",
            href: "/admin/menu",
            alternativeHref: "/admin/hub",
            icon: "carbon:tree-view",
          },
        ],
      },
      {
        title: "Contenuti",
        icon: "carbon:chart-bubble-packed",
        roles: ["Editore"],
        items: [
          {
            title: "Notizie",
            href: "/admin/contenuti/notizia",
            icon: "carbon:document-multiple-01",
          },
          {
            title: "Scadenzario",
            href: "/admin/contenuti/scadenza",
            icon: "carbon:calendar",
          },
          {
            title: "Pratiche",
            href: "/admin/contenuti/pratica",
            icon: "carbon:document-multiple-02",
          },
          {
            title: "Modelli",
            href: "/admin/contenuti/modello",
            icon: "carbon:result",
          },
          {
            title: "Link",
            href: "/admin/contenuti/link",
            icon: "carbon:link",
          },
          {
            title: "Info per il cittadino",
            href: "/admin/contenuti/postit",
            icon: "carbon:information",
          },
          {
            title: "Normativa fondamentale",
            href: "/admin/contenuti/norma",
            icon: "carbon:scales",
          },
          {
            title: "Scadenzario elettorale",
            href: "/admin/contenuti/scadenzario-elettorale",
            icon: "carbon:calendar",
          },
          {
            title: "Quesiti",
            href: "/admin/contenuti/quesito",
            icon: "carbon:help",
          },
          {
            title: "Riviste",
            href: "/admin/contenuti/rivista",
            alternativeHref: "/admin/contenuti/articolo-rivista",
            icon: "carbon:book",
          },
        ],
      },
      {
        title: "Memoweb",
        roles: ["Editore"],
        icon: "carbon:document-multiple-01",
        items: [
          {
            title: "Memoweb",
            href: "/admin/memoweb",
            icon: "carbon:document-multiple-01",
          },
        ],
      },
      {
        title: "Comunicazioni e avvisi",
        icon: "carbon:email",
        roles: ["Editore"],
        items: [
          {
            title: "Newsletter",
            href: "/admin/newsletter",
            icon: "carbon:email",
          },
        ],
      },
      {
        title: "Media",
        icon: "carbon:volume-file-storage",
        roles: ["Editore"],
        items: [
          {
            title: "File e cartelle",
            href: "/admin/drive",
            icon: "carbon:cloud",
          },
        ],
      },
      {
        title: "Clienti",
        roles: ["Ufficio clienti"],
        icon: "carbon:finance",
        items: [
          {
            title: "Clienti",
            href: "/admin/clienti",
            icon: "carbon:finance",
          },
          {
            title: "Utenti",
            href: "/admin/utenti",
            icon: "carbon:user-multiple",
          },
          {
            title: "Prodotti",
            href: "/admin/prodotti",
            icon: "carbon:cube",
          },
          {
            title: "Richieste demo/preventivo",
            href: "/admin/richieste-demo",
            icon: "carbon:email",
          },
        ],
      },
      {
        title: "Quesiti",
        roles: ["Editore"],
        icon: "carbon:help",
        items: [
          {
            title: "Quesiti inviati",
            href: "/admin/quesiti-inviati",
            icon: "carbon:help",
          },
        ],
      },
    ],
  },
  {
    title: "Opzioni sviluppatore",
    roles: ["SuperAdmin", "Amministratore"],
    items: [
      {
        title: "Email",
        roles: ["Amministratore", "SuperAdmin"],
        icon: "carbon:email",
        items: [
          {
            title: "Blacklist email",
            href: "/admin/blacklist",
            icon: "carbon:email",
          },
        ],
      },
      {
        title: "Utilità",
        roles: ["Amministratore", "SuperAdmin"],
        icon: "carbon:tools",
        items: [
          {
            title: "Cache",
            href: "/admin/utilita/cache",
            icon: "carbon:db2-database",
          },
          {
            title: "Contenuti",
            href: "/admin/utilita/contenuti",
            icon: "carbon:chart-bubble-packed",
          },
        ],
      },
    ],
  },
] as {
  title: string;
  roles?: string[];
  items: {
    title: string;
    roles?: string[];
    icon: string;
    items: MenuItem[];
  }[];
}[];

const menus = computed(() => {
  if (!loggedUser.value) return [];

  if (userHasRole(loggedUser.value, ["SuperAdmin", "Amministratore"])) return menuTree;

  return menuTree
    .filter((m) => !m.roles || userHasRole(loggedUser.value, m.roles))
    .map((m) => ({
      ...m,
      items: m.items
        .filter((menu) => {
          if (!loggedUser.value) return false;

          if (menu.roles) return userHasRole(loggedUser.value, menu.roles);
          return true;
        })
        .map((menu) => {
          return {
            ...menu,
            items: menu.items.filter((item) => {
              if (!loggedUser.value) return false;

              if (item.roles) return userHasRole(loggedUser.value, item.roles);
              return true;
            }),
          };
        }),
    }));
});

function isActive(item: MenuItem) {
  return route.path === item.href || route.path.startsWith(item.href + "/") || (item.alternativeHref ? route.path.startsWith(item.alternativeHref) : false);
}
</script>

<template>
  <ClientOnly v-if="status === 'success' && loggedUser">
    <SidebarProvider>
      <Sidebar collapsible="offcanvas" variant="inset">
        <SidebarHeader>
          <NuxtLink to="/" class="h-14 flex items-center px-3">
            <Logo class="h-8" />
          </NuxtLink>
        </SidebarHeader>
        <SidebarContent>
          <SidebarGroup v-for="menuSection in menus">
            <SidebarGroupLabel class="text-sm font-semibold">{{ menuSection.title }}</SidebarGroupLabel>
            <SidebarMenu>
              <Collapsible v-for="menuGroup in menuSection.items" :key="menuGroup.title" as-child :default-open="true" class="group/collapsible">
                <SidebarMenuItem>
                  <CollapsibleTrigger as-child>
                    <SidebarMenuButton :tooltip="menuGroup.title" class="text-base h-9">
                      <Icon :name="menuGroup.icon" />
                      <span>{{ menuGroup.title }}</span>
                      <ChevronRight class="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
                    </SidebarMenuButton>
                  </CollapsibleTrigger>
                  <CollapsibleContent>
                    <SidebarMenuSub>
                      <SidebarMenuSubItem v-for="subItem in menuGroup.items" :key="subItem.title">
                        <SidebarMenuSubButton as-child class="text-base h-9" :is-active="isActive(subItem)">
                          <NuxtLink :href="subItem.href">
                            <Icon :name="subItem.icon" class="w-4 h-4 mr-1" />
                            <span>{{ subItem.title }}</span>
                          </NuxtLink>
                        </SidebarMenuSubButton>
                      </SidebarMenuSubItem>
                    </SidebarMenuSub>
                  </CollapsibleContent>
                </SidebarMenuItem>
              </Collapsible>
            </SidebarMenu>
          </SidebarGroup>
        </SidebarContent>
        <SidebarFooter>
          <SidebarMenu>
            <SidebarMenuItem>
              <DropdownMenu>
                <DropdownMenuTrigger as-child>
                  <SidebarMenuButton size="lg" class="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground">
                    <Avatar class="h-8 w-8 rounded-lg">
                      <AvatarFallback class="rounded-lg">{{ loggedUser.name.charAt(0) + (loggedUser.surname?.charAt(0) || loggedUser.name.charAt(1) || "") }}</AvatarFallback>
                    </Avatar>
                    <div class="grid flex-1 text-left text-sm leading-tight">
                      <span class="truncate font-semibold">{{ loggedUser.full_name }}</span>
                      <span class="truncate text-xs">{{ loggedUser.email }}</span>
                    </div>
                    <ChevronsUpDown class="ml-auto size-4" />
                  </SidebarMenuButton>
                </DropdownMenuTrigger>
                <DropdownMenuContent class="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg" side="bottom" align="end" :side-offset="4">
                  <DropdownMenuLabel class="p-0 font-normal">
                    <div class="flex items-center gap-2 px-1 py-1.5 text-left text-sm">
                      <Avatar class="h-8 w-8 rounded-lg">
                        <AvatarFallback class="rounded-lg">{{ loggedUser.name.charAt(0) + (loggedUser.surname?.charAt(0) || loggedUser.name.charAt(1) || "") }}</AvatarFallback>
                      </Avatar>
                      <div class="grid flex-1 text-left text-sm leading-tight">
                        <span class="truncate font-semibold">{{ loggedUser.full_name }}</span>
                        <span class="truncate text-xs">{{ loggedUser.email }}</span>
                      </div>
                    </div>
                  </DropdownMenuLabel>
                  <DropdownMenuSeparator />
                  <DropdownMenuGroup>
                    <DropdownMenuItem><NuxtLink class="w-full" href="/">Vai al sito pubblico</NuxtLink></DropdownMenuItem>
                    <DropdownMenuSub>
                      <DropdownMenuSubTrigger>Tema</DropdownMenuSubTrigger>
                      <DropdownMenuPortal>
                        <DropdownMenuSubContent>
                          <DropdownMenuRadioGroup v-model="colorMode.preference">
                            <DropdownMenuRadioItem value="system">Automatico</DropdownMenuRadioItem>
                            <DropdownMenuRadioItem value="light">Chiaro</DropdownMenuRadioItem>
                            <DropdownMenuRadioItem value="dark">Scuro</DropdownMenuRadioItem>
                          </DropdownMenuRadioGroup>
                        </DropdownMenuSubContent>
                      </DropdownMenuPortal>
                    </DropdownMenuSub>
                  </DropdownMenuGroup>
                  <DropdownMenuSeparator />
                  <DropdownMenuCheckboxItem v-model:checked="preview">Anteprima</DropdownMenuCheckboxItem>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem @click="logout">Esci</DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </SidebarMenuItem>
          </SidebarMenu>
        </SidebarFooter>
        <SidebarRail />
      </Sidebar>
      <SidebarInset>
        <header class="flex h-16 shrink-0 items-center gap-2 transition-[width,height] ease-linear group-has-[[data-collapsible=icon]]/sidebar-wrapper:h-12">
          <div class="flex items-center gap-2 px-4">
            <SidebarTrigger />
            <Separator orientation="vertical" class="mr-2 h-4" />
            <div id="breadcrumbs"></div>
          </div>
        </header>

        <div>
          <main class="grid flex-1 px-4 md:px-6 py-4 mx-auto w-full">
            <div class="main-edit pb-28">
              <ClientOnly>
                <slot></slot>
              </ClientOnly>
            </div>
          </main>
        </div>
      </SidebarInset>
    </SidebarProvider>
  </ClientOnly>
</template>
